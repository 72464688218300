#almostthereContainer {
  @apply bg-gray-base font-sans font-bold #{!important};

  #qrcode {
    @apply bg-primary-base p-lg #{!important};
  }

  .desktop-hint {
    @apply text-primary-base #{!important};
  }

  .desktop-message {
    @apply text-primary-base #{!important};
  }

  .desktop-home-link {
    @apply bg-gray-dark font-bold tracking-wide text-secondary-base #{!important};
  }

  .poweredby-img {
    @apply rounded-3xl bg-gray-dark px-md py-sm;
  }
}

#requestingCameraPermissions {
  @apply bg-primary-base font-sans #{!important};
}

#loadBackground {
  @apply bg-gray-base  #{!important};
}
#loadImage {
  @apply m-0 h-auto w-[100px] -translate-x-1/2 -translate-y-1/2 animate-none #{!important};
}

.prompt-box-8w {
  @apply rounded-none bg-primary-base font-sans #{!important};
  .prompt-button-8w {
    @apply flex h-[40px] w-max cursor-pointer items-center justify-center rounded-none bg-white text-lg font-medium uppercase text-primary-base #{!important};
  }
}
