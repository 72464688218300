.swiper-pagination-bullet-active {
  @apply bg-primary-base #{!important};
}

.swiper-button-next, .swiper-button-prev {
  opacity: 0.7;
  @apply transition text-primary-base #{!important};
}

.swiper-button-disabled {
  opacity: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  position: fixed;
  inset: 0;
  overflow-x: hidden;
  @apply text-base;
}

input {
  -webkit-user-select: text;
}

* {
  user-select: none;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  @apply bg-gradient-to-r from-gray-dark to-gray-base;
}

::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-base;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0px;
}

