@font-face {
  font-family: 'Neo Sans';
  src: url('~/public/assets/fonts/neo-sans/Neo Sans Std Light.otf') format('opentype');
  font-weight: 300;
  ascent-override: 110%;
}

@font-face {
  font-family: 'Neo Sans';
  src: url('~/public/assets/fonts/neo-sans/Neo Sans Std Regular.otf') format('opentype');
  font-weight: 400;
  ascent-override: 110%;
}

@font-face {
  font-family: 'Neo Sans';
  src: url('~/public/assets/fonts/neo-sans/Neo Sans Std Medium.otf') format('opentype');
  font-weight: 500;
  ascent-override: 110%;
}

@font-face {
  font-family: 'Neo Sans';
  src: url('~/public/assets/fonts/neo-sans/Neo Sans Std Bold.otf') format('opentype');
  font-weight: 700;
  ascent-override: 110%;
}
